<template>
  <div class="register-bg d-flex align-items-center justify-content-center">
    <div class="loading" v-if="isLoading">
      <OtherLoading />
    </div>
    <div class="w-100">
      <b-row class="justify-content-center align-items-center">
        <b-col md="9">
          <b-row class="header-panel">
            <b-col>
              <p>ยินดีต้อนรับ</p>
            </b-col>
          </b-row>
          <b-row class="body-panel justify-content-center h-100">
            <b-col md="5" class="bg-black p-0">
              <div class="picture"></div>
            </b-col>
            <b-col md="7" class="px-md-0">
              <div v-if="isMember">
                <p class="text-title">เข้าสู่ระบบ</p>
                <div class="p-3 register-box">
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">
                        โทร (Telephone) <span class="text-danger">*</span>
                      </p>
                    </b-col>
                    <b-col>
                      <InputText
                        name="telephone"
                        placeholder="เบอร์โทรศัพท์ 10 หลัก"
                        v-model="form.telephone"
                        :isValidate="$v.form.telephone.$error"
                        :v="$v.form.telephone"
                        @onKeypress="isNumber($event)"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">
                        วันเกิด (Birthday) <span class="text-danger">*</span>
                      </p>
                    </b-col>
                    <b-col>
                      <InputDatePickerFilter
                        name="birthday"
                        ref="birthday"
                        v-model="form.birthday"
                        @input="changeBirthday"
                        :maxDatetime="now"
                        :isValidate="$v.form.birthday.$error"
                        :v="$v.form.birthday"
                      />
                    </b-col>
                  </b-row>
                  <div class="text-right">
                    <b-button
                      variant="outline-primary-color"
                      class="px-4 py-2 rounded-pill mb-2 mr-3"
                      :disabled="isLoading"
                      @click="handleMember(false)"
                    >
                      ย้อนกลับ
                    </b-button>
                    <b-button
                      variant="primary-color"
                      class="px-4 py-2 rounded-pill mb-2"
                      :disabled="isLoading"
                      @click="login"
                    >
                      ตกลง
                    </b-button>
                  </div>
                </div>
              </div>
              <div v-else-if="step == 1">
                <p class="text-title">ข้อมูลส่วนตัว</p>
                <div class="p-3 register-box">
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">
                        ชื่อ (Name) <span class="text-danger">*</span>
                      </p>
                    </b-col>
                    <b-col>
                      <InputText
                        v-model="form.firstname"
                        placeholder="ชื่อภาษาไทย"
                        name="firstname"
                        :isValidate="$v.form.firstname.$error"
                        :v="$v.form.firstname"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">
                        นามสกุล (Surname) <span class="text-danger">*</span>
                      </p>
                    </b-col>
                    <b-col>
                      <InputText
                        v-model="form.lastname"
                        placeholder="นามสกุลภาษาไทย"
                        name="lastname"
                        :isValidate="$v.form.lastname.$error"
                        :v="$v.form.lastname"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">Name</p>
                    </b-col>
                    <b-col>
                      <InputText
                        placeholder="ชื่อภาษาอังกฤษ"
                        v-model="form.firstname_en"
                        name="firstname_en"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">Surname</p>
                    </b-col>
                    <b-col>
                      <InputText
                        placeholder="นามสกุลภาษาอังกฤษ"
                        v-model="form.lastname_en"
                        name="lastname_en"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">
                        ชื่อเล่น (Nickname)
                      </p>
                    </b-col>
                    <b-col>
                      <InputText
                        placeholder="ชื่อเล่นภาษาไทย"
                        v-model="form.nickname"
                        name="nickname"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">
                        วันเกิด (Birthday) <span class="text-danger">*</span>
                      </p>
                    </b-col>
                    <b-col>
                      <InputDatePickerFilter
                        name="birthday"
                        ref="birthday"
                        v-model="form.birthday"
                        @input="changeBirthday"
                        :maxDatetime="now"
                        :isValidate="$v.form.birthday.$error"
                        :v="$v.form.birthday"
                        placeholder="DD/MM/YYYY"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-4">
                    <b-col cols="4">
                      <p class="text-title-input">
                        เพศ (Gender) <span class="text-danger">*</span>
                      </p>
                    </b-col>
                    <b-col>
                      <InputRadioGroup
                        id="gender"
                        name="gender"
                        v-model="form.gender"
                        :options="genderList"
                        @onDataChange="changeGender"
                        :isValidate="$v.form.gender.$error"
                        :v="$v.form.gender"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">
                        โทร (Telephone) <span class="text-danger">*</span>
                      </p>
                    </b-col>
                    <b-col>
                      <InputText
                        name="telephone"
                        placeholder="เบอร์โทรศัพท์ 10 หลัก"
                        v-model="form.telephone"
                        :isValidate="$v.form.telephone.$error"
                        :v="$v.form.telephone"
                        @onKeypress="isNumber($event)"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">
                        อีเมล (Email) <span class="text-danger">*</span>
                      </p>
                    </b-col>
                    <b-col>
                      <InputText
                        name="email"
                        placeholder="อีเมล"
                        v-model="form.email"
                        :isValidate="$v.form.email.$error"
                        :v="$v.form.email"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">Line ID</p>
                    </b-col>
                    <b-col>
                      <InputText
                        placeholder="Line ID"
                        name="line_id"
                        v-model="form.line_id"
                      />
                    </b-col>
                  </b-row>
                  <div class="text-right">
                    <b-button
                      variant="primary-color"
                      class="px-4 py-2 rounded-pill mb-2"
                      :disabled="isLoading"
                      @click="handleNext"
                    >
                      ต่อไป
                    </b-button>
                    <br />
                    <b-button
                      variant="link"
                      :disabled="isLoading"
                      @click="handleMember(true)"
                      v-if="form.isRegister"
                    >
                      เป็นสมาชิกอยู่แล้ว
                    </b-button>
                  </div>
                </div>
              </div>
              <div v-else-if="step == 2">
                <p class="text-title">ข้อมูลที่อยู่</p>
                <div class="p-3 register-box">
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">บ้านเลขที่ (House No.)</p>
                    </b-col>
                    <b-col>
                      <InputText
                        placeholder="บ้านเลขที่"
                        v-model="form.home_address"
                        name="home_address"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">อาคาร (Building)</p>
                    </b-col>
                    <b-col>
                      <InputText
                        placeholder="อาคาร"
                        v-model="form.town"
                        name="town"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">ซอย (Lane)</p>
                    </b-col>
                    <b-col>
                      <InputText
                        placeholder="ซอย"
                        v-model="form.alley"
                        name="alley"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">ถนน (Road)</p>
                    </b-col>
                    <b-col>
                      <InputText
                        placeholder="ถนน"
                        v-model="form.road"
                        name="road"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">แขวง (Sub-district)</p>
                    </b-col>
                    <b-col>
                      <InputText
                        placeholder="แขวง"
                        v-model="form.subdistrict"
                        name="subdistrict"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">เขต (District)</p>
                    </b-col>
                    <b-col>
                      <InputText
                        placeholder="เขต"
                        v-model="form.district"
                        name="district"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">จังหวัด (Province)</p>
                    </b-col>
                    <b-col>
                      <InputText
                        placeholder="จังหวัด"
                        v-model="form.province"
                        name="province"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4" class="pr-0">
                      <p class="text-title-input">รหัสไปรษณีย์ (Postal Code)</p>
                    </b-col>
                    <b-col>
                      <InputText
                        name="zip_code"
                        placeholder="รหัสไปรษณีย์"
                        v-model="form.zip_code"
                        className="mb-2"
                        :isValidate="$v.form.zip_code.$error"
                        :v="$v.form.zip_code"
                        @onKeypress="isNumber($event)"
                      />
                    </b-col>
                  </b-row>
                  <p class="desc-privacy mb-2">
                    คลิกเลือกด้านล่าง เพื่อให้เราเข้าใจท่านมากขึ้น
                    และนำเสนอประสบการณ์ที่ดีที่สุดให้กับท่าน / Let us understand
                    you more to deliver you the best experience. Check the box
                    below and stay in touch!
                  </p>
                  <div class="mb-2">
                    <b-form-checkbox
                      v-model="form.is_consent"
                      :value="1"
                      :unchecked-value="0"
                    >
                      <label class="privacy-txt">
                        ฉันต้องการรับสิทธิพิเศษและโปรโมชั่น ตามที่ระบุไว้ใน
                        <router-link to="#" target="_blank"
                          >นโยบายความเป็นส่วนตัว</router-link
                        >
                        / I would like to receive my privileges specified in
                        <router-link to="#" target="_blank"
                          >Privacy Policy</router-link
                        >
                      </label>
                    </b-form-checkbox>
                  </div>
                  <div class="mb-2">
                    <b-form-checkbox
                      v-model="form.is_privacy_policy"
                      :value="1"
                      :unchecked-value="0"
                    >
                      <label class="privacy-txt">
                        ฉันยอมรับ
                        <router-link to="#" target="_blank"
                          >นโยบายความเป็นส่วนตัว</router-link
                        >
                        และ
                        <router-link to="#" target="_blank"
                          >ข้อกำหนดเงื่อนไข</router-link
                        >
                        / I accept the
                        <router-link to="#" target="_blank"
                          >Privacy Policy</router-link
                        >
                        and acknowledge the
                        <router-link to="#" target="_blank"
                          >Terms and Conditions</router-link
                        >
                      </label>
                    </b-form-checkbox>
                  </div>
                  <div class="text-right">
                    <div v-if="form.user_guid">
                      <b-button
                        variant="outline-primary-color"
                        class="px-4 py-2 rounded-pill mb-2"
                        :disabled="isLoading"
                        @click="backRegister"
                      >
                        กลับไป Register
                      </b-button>
                      <b-button
                        variant="outline-primary-color"
                        class="px-4 py-2 rounded-pill mb-2 mx-2"
                        :disabled="isLoading"
                        @click="step = 1"
                      >
                        ย้อนกลับ
                      </b-button>
                      <b-button
                        variant="primary-color"
                        class="px-4 py-2 rounded-pill mb-2"
                        :disabled="isLoading || !form.is_privacy_policy"
                        @click="save"
                      >
                        อัปเดต
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-primary-color"
                        class="px-4 py-2 rounded-pill mb-2 mr-3"
                        :disabled="isLoading"
                        @click="step = 1"
                      >
                        ย้อนกลับ
                      </b-button>
                      <b-button
                        variant="primary-color"
                        class="px-4 py-2 rounded-pill mb-2"
                        :disabled="isLoading || !form.is_privacy_policy"
                        @click="save"
                      >
                        เสร็จสิ้น
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import {
  required,
  requiredIf,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import InputRadioGroup from "@/components/inputs/InputRadioGroup";
import { mapGetters } from "vuex";

export default {
  components: {
    OtherLoading,
    InputSelect,
    InputText,
    InputDatePickerFilter,
    InputRadioGroup,
  },
  name: "NewCustomer",
  data() {
    return {
      isLoading: true,
      form: {
        id: 0,
        firstname: "",
        lastname: "",
        firstname_en: "",
        lastname_en: "",
        gender: "",
        birthday: "",
        telephone: "",
        home_address: "",
        alley: "",
        road: "",
        town: "",
        zip_code: "",
        district: "",
        subdistrict: "",
        province: "",
        branch_id: 0,
        email: "",
        password: "",
        nickname: "",
        t_datetime: "",
        line_id: "",
        the_one_card_member: 0,
        line_ref: "",
        is_ba: 1,
        is_consent: 0,
        is_privacy_policy: 0,
        user_guid: "",
        line_ref: "",
        isRegister: true,
        isUpdate: false,
      },
      now: null,
      genderList: [
        { text: "ชาย", value: "Male" },
        { text: "หญิง", value: "Female" },
        { text: "ไม่ระบุเพศ", value: "N/A" },
      ],
      isMember: false,
      step: 1,
    };
  },
  validations() {
    return {
      form: {
        firstname: {
          required: requiredIf(function (item) {
            return !this.isMember;
          }),
        },
        lastname: {
          required: requiredIf(function (item) {
            return !this.isMember;
          }),
        },
        birthday: { 
          required,
          maxValueToday: (value) => {
            let dateTime = this.$moment(value).unix();
            let now = this.$moment().unix();
            return dateTime <= now;
          },
        },
        gender: {
          required: requiredIf(function (item) {
            return !this.isMember;
          }),
        },
        telephone: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
        email: {
          required: requiredIf(function (item) {
            return !this.isMember;
          }),
          email,
        },
        zip_code: { minLength: minLength(5), maxLength: maxLength(5) },
      },
    };
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
    }),
  },
  created: async function () {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
    this.isLoading = false;
  },
  methods: {
    clearData() {
      this.$v.form.$reset();
      this.form = {
        id: 0,
        firstname: "",
        lastname: "",
        firstname_en: "",
        lastname_en: "",
        gender: "",
        birthday: "",
        telephone: "",
        home_address: "",
        alley: "",
        road: "",
        town: "",
        zip_code: "",
        district: "",
        subdistrict: "",
        province: "",
        branch_id: 0,
        email: "",
        password: "",
        nickname: "",
        t_datetime: "",
        line_id: "",
        the_one_card_member: 0,
        line_ref: "",
        is_ba: 1,
        is_consent: 0,
        is_privacy_policy: 0,
        user_guid: "",
        line_ref: "",
        isRegister: true,
        isUpdate: false,
      };
    },
    handleMember(isMember){
      this.clearData();
      this.isMember = isMember
    },
    changeBirthday(val) {
      this.form.birthday = val;
    },
    changeGender(val) {
      this.form.gender = val;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    randomPass(count = false) {
      let result = "";
      const randomCount = count || 32;
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < randomCount) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        this.step = 1;
        return;
      }

      this.isLoading = true;
      if (this.form.isRegister) {
        await this.fullRegister();
      } else {
        await this.updateCustomerDetail();
      }
      this.isLoading = false;
    },
    fullRegister: async function () {
      let body = { ...this.form };
      body.telephone = body.telephone.trim();
      body.t_datetime =
        this.dateTimeFix || this.$moment().format("YYYY-MM-DD HH:mm:ss");
      body.password = await this.randomPass(32);

      await this.axios
        .post(`${this.$baseUrl}/customer/register`, body)
        .then((data) => {
          if (data.result == 1) {
            // this.successAlert();
            this.$router
              .push({
                path: `/customer/success`,
                query: {
                  memberId: data.detail.member_id,
                  name: `${body.firstname} ${body.lastname}`,
                  isRegister: "true",
                },
              })
              .catch(() => {});
          } else {
            this.warningAlert(data.message);
          }
        });
    },
    updateCustomerDetail: async function () {
      let body = { ...this.form };
      body.telephone = body.telephone.trim();
      body.birthday = this.$moment(body.birthday).format("YYYY-MM-DD HH:mm:ss");
      body.the_one_card_member = parseInt(body.the_one_card_member);

      await this.axios
        .post(`${this.$baseUrl}/customer/update_customer_detail`, body)
        .then((data) => {
          if (data.result == 1) {
            // this.successAlert();
            this.$router
              .push({
                path: `/customer/success`,
                query: {
                  memberId: "",
                  name: `${body.firstname} ${body.lastname}`,
                  isRegister: "false",
                },
              })
              .catch(() => {});
          } else {
            this.warningAlert(data.message);
          }
        });
    },
    login: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      this.error = "";
      let body = {
        birthday: this.form.birthday,
        telephone: this.form.telephone.trim(),
      };

      this.axios
        .post(`${this.$baseUrl}/customer/login_with_phonenumber`, body)
        .then(async (data) => {
          if (data.result == 1) {
            this.$v.form.$reset();
            this.form = { ...data.detail };
            this.form.firstname = data.detail.first_name_th;
            this.form.lastname = data.detail.last_name_th;
            this.form.email = data.detail.email.trim();
            this.form.isRegister = false;
            this.form.is_privacy_policy = 1;
            this.step = 1;
            this.isMember = false;
          } else {
            this.warningAlert(data.message);
          }
          this.isLoading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleNext() {
      this.step = 2;
    },
    backRegister() {
      this.form.isRegister = true;
      this.clearData();
      this.step = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.register-bg {
  background-image: url(./../../../../assets/images/icons/register-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
}
.loading {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 500;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: rgba(255, 255, 255, 0.5);
}
.header-panel {
  text-align: center;
  padding: 10px;
  color: #fff;
  font-size: 20px;
  background-color: var(--primary-color);
}
::v-deep .body-panel {
  background-color: #ffffff;
  border: 3px solid var(--primary-color) !important;
  .text-title {
    text-align: center;
    color: var(--primary-color);
    padding: 10px 0;
    font-size: 20px;
  }
  .text-title-input {
    color: #707070;
    font-size: 16px;
    font-weight: normal;
    padding-top: 5px;
  }
  .picture {
    width: 100%;
    height: 100%;
    background-image: url(./../../../../assets/images/icons/register_banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--primary-color);
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
  }
  .btn-link {
    background-color: transparent !important;
    color: var(--primary-color) !important;
    text-decoration: underline;
    padding: 0;
  }
  .desc-privacy {
    font-size: 12px;
  }
  .privacy-txt {
    color: #707070;
    display: inline;
    font-size: 12px;
    a {
      color: #707070 !important;
      text-decoration: underline;
    }
  }
  .register-box {
    min-height: 670px;
  }
}
</style>
